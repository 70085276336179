<template>
  <div v-if="canAccess('ciudades_read')" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing" />

        <DataTable
          :value="items"
          :filters="filters"
          class="p-datatable-responsive-demo p-datatable-sm"
          v-model:selection="currentItem"
          selectionMode="single"
          dataKey="id"
          :paginator="true"
          paginatorPosition="bottom"
          :rows="10"
        >
          <template #header>
            <div class="p-grid p-col-12 p-justify-between">
              <h5 class="p-m-1">Gestionar Ciudades</h5>
              <div>
                <span class="p-input-icon-left p-m-1">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global']" placeholder="Search" />
                </span>
                <Button
                  v-if="canAccess('ciudades_create')"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-success p-m-1"
                  @click="openNew"
                />
              </div>
            </div>
          </template>

          <Column field="id" header="Código" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              <span class="p-column-value">
                {{ slotProps.data.id }}
              </span>
            </template>
          </Column>
          <Column field="name" header="Nombre" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              <span class="p-column-value">
                {{ slotProps.data.name }}
              </span>
            </template>
          </Column>
          <Column field="code" header="Código" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              <span class="p-column-value">
                {{ slotProps.data.code }}
              </span>
            </template>
          </Column>
          <Column field="country.name" header="País" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">País</span>
              <span class="p-column-value">
                {{ slotProps.data.country.name }}
              </span>
            </template>
          </Column>
          <Column header="Opciones">
            <template #body="slotProps">
              <span class="p-column-title">Opciones</span>
              <Button
                v-if="canAccess('ciudades_update')"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="edit(slotProps.data)"
              />
              <Button
                v-if="canAccess('ciudades_delete')"
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Dialog
          v-if="canAccess('ciudades_update') || canAccess('ciudades_create')"
          v-model:visible="formDialog"
          :style="{ width: '28.125rem' }"
          header="Ciudad"
          :modal="true"
          class="p-fluid"
        >
          <br />
          <div class="p-field   p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <InputText
                id="name"
                v-model.trim="currentItem.name"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !currentItem.name }"
              />
              <label for="count">Nombre</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.name"
              >El nombre es requerido</small
            >
          </div>

          <br />
          <div class="p-field p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <AutoComplete
                id="count"
                :dropdown="true"
                :multiple="false"
                field="name"
                v-model="currentItem.country"
                :suggestions="countries"
                @complete="getCatalogue($event, 'countries', 'name')"
                :class="{ 'p-invalid': submitted && !currentItem.country }"
              />
              <label for="count">País</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.country"
              >El país es requerido</small
            >
          </div>
          <br />
          <div class="p-field p-fluid" v-if="currentItem">
            <span class="p-float-label">
              <InputText
                id="code"
                v-model.trim="currentItem.code"
                required="true"
                autofocus
                :class="{ 'p-invalid': submitted && !currentItem.code }"
              />
              <label for="count">Código</label>
            </span>
            <small class="p-invalid" v-if="submitted && !currentItem.code"
              >El código es requerido</small
            >
          </div>
          <template #footer>
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItem" />
          </template>
        </Dialog>
        <Dialog
          v-if="canAccess('ciudades_delete')"
          v-model:visible="confirmDeleteModal"
          :style="{ width: '28.125rem' }"
          header="Confirm"
          :modal="true"
        >
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="currentItem">Seguro de eliminar registro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="confirmDeleteModal = false"
            />
            <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteRow" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button
        label="Regresar"
        class="p-button-danger p-mr-2"
        @click="$router.push('/admin')"
      />
    </div>
  </div>
</template>

<script src="./City.js"></script>
<style src="./City.scss" lang="scss" scoped></style>
